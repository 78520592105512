import Link from 'next/link'
import { useRouter } from 'next/router'
import cn from 'classnames'
import { LogoGray } from '../icons/LogoGray'
import GraphQLWeeklyLogo from 'icons/GraphQLWeeklyLogo'
import IsThisGraphQLLogo from 'icons/IsThisGraphQLLogo'
import GraphQLRadioLogo from 'icons/GraphQLRadioLogo'
import AnalyticsIconGrey from 'icons/AnalyticsIconGrey'
import RateLimitingIconGrey from 'icons/RateLimitingIconGrey'
import EdgeCachingIconGrey from 'icons/EdgeCachingIconGrey'
import SocialChannels from './marketing/SocialChannels'
import {
  FEATURE_EDGE_CACHING,
  FEATURE_METRICS,
  FEATURE_RATE_LIMITING,
} from 'common/constants'
import { Soc2Type2Icon } from '../icons/Soc2Icon'

declare global {
  interface Window {
    Cookiebot?: {
      renew: () => void
    }
  }
}

export const Footer = (): JSX.Element => {
  const router = useRouter()

  const iconClasses = 'w-6 h-auto flex-shrink-0 md:w-8'

  return (
    <footer className='marketing-page font-demibold relative max-w-screen-2xl flex flex-col space-y-20 px-6 py-12 mx-auto border-t border-neutral-10 sm:px-8 md:px-20 md:py-14 xl:space-y-24'>
      <div className='flex flex-col xl:flex-row xl:items-start xl:justify-between'>
        <LogoInfo
          className='hidden xl:flex' // Hidden on smaller devices
        />

        <nav
          className='flex flex-wrap flex-col lg:flex-row -mt-14 sm:-mt-16 xl:-mt-24'
          aria-label='Footer'
        >
          <NavGroup
            groupName={'Features'}
            className='pt-14 pr-14 sm:pt-16 sm:pr-16 xl:pt-24 xl:pr-24'
          >
            <NavItem href='/graphql-edge-caching'>
              <EdgeCachingIconGrey className={iconClasses} />
              <span>{FEATURE_EDGE_CACHING}</span>
            </NavItem>
            <NavItem href='/graphql-metrics'>
              <AnalyticsIconGrey className={iconClasses} />
              <span>{FEATURE_METRICS}</span>
            </NavItem>
            <NavItem href='/graphql-rate-limiting'>
              <RateLimitingIconGrey className={iconClasses} />
              <span>{FEATURE_RATE_LIMITING}</span>
            </NavItem>
          </NavGroup>

          <NavGroup
            groupName={'Product'}
            className='pt-14 pr-14 sm:pt-16 sm:pr-16 xl:pt-24 xl:pr-24'
          >
            <NavItem href='/pricing'>Pricing</NavItem>
            <NavItem href='/enterprise'>Enterprise</NavItem>
            <NavItem href='https://go.stellate.co/for-agencies'>
              Agencies
            </NavItem>
            <NavItem href='/docs'>Docs</NavItem>
            <NavItem href='https://changelog.stellate.co' target='_blank'>
              Changelog
            </NavItem>
            <NavItem href='https://discord.gg/ZyH8MYFzrF' target='_blank'>
              Discord Community
            </NavItem>
            <NavItem href='/love'>Wall of Love</NavItem>
            <NavItem
              href='mailto:support@stellate.co'
              onClick={(evt) => {
                // If Intercom is loaded, send them there
                // otherwise send them to the email link above (href)
                if (window['Intercom']?.booted) {
                  evt.preventDefault()
                  window['Intercom']('show')
                }
              }}
            >
              Support
            </NavItem>
            <div className='flex space-x-2 items-center'>
              <NavItem href='https://status.stellate.co' target='_blank'>
                Status
              </NavItem>
              {/* TODO: check Stellate’s GraphQL Edge Caching status and change circle color accordingly */}
              <span className='inline-block w-2 h-2 rounded-full bg-green' />
            </div>
          </NavGroup>

          <NavGroup
            groupName={'Company'}
            className='pt-14 pr-14 sm:pt-16 sm:pr-16 xl:pt-24 xl:pr-24'
          >
            <NavItem href='/about'>About</NavItem>
            <NavItem href='/careers'>
              <span>Careers</span>

              {/*<small className='tag'>We&apos;re hiring!</small>*/}
            </NavItem>
            <NavItem href='/blog'>Blog</NavItem>
            <NavItem href='/press'>Press</NavItem>
            <NavItem href='https://climate.stripe.com/wMbSdD' target='_blank'>
              1% for climate
            </NavItem>
          </NavGroup>

          <NavGroup
            groupName={'Security'}
            className='pt-14 sm:pt-16 xl:pt-24 !max-w-[210px]'
          >
            <div className='flex gap-2'>
              <Soc2Type2Icon width={48} height={48} />
            </div>
            <div>
              <h3 className='!text-18 pb-2 text-[#63748C]'>
                SOC2 Type <span className='sr-only'>1</span>
                <span aria-hidden='true'>I</span> and{' '}
                <span className='sr-only'>2</span>
                <span aria-hidden='true'>II</span> Certified
              </h3>
              <p className='text-15 text-[#63748C]'>
                Stellate continuously monitors and reports primarily using
                System and Organization Controls (SOC) 2 Type{' '}
                <span className='sr-only'>1</span>
                <span aria-hidden='true'>I</span> and{' '}
                <span className='sr-only'>2</span>
                <span aria-hidden='true'>II</span>.
              </p>
            </div>
          </NavGroup>
        </nav>
      </div>

      <div className='flex flex-col justify-between space-y-8 xl:flex-row'>
        <div className='space-y-6 lg:flex lg:space-y-0 lg:space-x-6'>
          <ExternalResource
            href='https://www.graphqlweekly.com'
            logo={<GraphQLWeeklyLogo className={iconClasses} />}
            name='GraphQL Weekly'
            briefDescription='Newsletter'
          />

          <ExternalResource
            href='https://chrome.google.com/webstore/detail/is-this-graphql/bpddjcoknlkjonemmdokaeeplmjhhnhh'
            logo={<IsThisGraphQLLogo className={iconClasses} />}
            name='Is this GraphQL'
            briefDescription='Chrome Extension'
          />

          <ExternalResource
            href='https://graphqlradio.com/'
            logo={<GraphQLRadioLogo className={iconClasses} />}
            name='GraphQL Radio'
            briefDescription='Podcast'
          />
        </div>

        <div className='absolute bottom-12 xl:static xl:bottom-0 flex space-x-4 mt-auto small-body-text'>
          <Link
            href='/terms'
            // Has to be the same as the href slug
            tabIndex={router.asPath === '/terms' ? -1 : 0}
            className={`min-clickable-area w-fit-content leading-tight transition-opacity hover:opacity-80 focus:opacity-80 ${
              router.asPath === '/terms' // Has to be the same as the href slug
                ? 'opacity-80 pointer-events-none'
                : 'opacity-40'
            }`}
          >
            Terms of Service
          </Link>
          <Link
            href='/privacy'
            // Has to be the same as the href slug
            tabIndex={router.asPath === '/privacy' ? -1 : 0}
            className={`min-clickable-area w-fit-content leading-tight transition-opacity hover:opacity-80 focus:opacity-80 ${
              router.asPath === '/privacy' // Has to be the same as the href slug
                ? 'opacity-80 pointer-events-none'
                : 'opacity-40'
            }`}
          >
            Privacy Policy
          </Link>
          <Link href='/security' passHref legacyBehavior>
            <a
              tabIndex={router.asPath === '/security' ? -1 : 0} // Has to be the same as the href slug
              className={`min-clickable-area w-fit-content leading-tight transition-opacity hover:opacity-80 focus:opacity-80 ${
                router.asPath === '/security' // Has to be the same as the href slug
                  ? 'opacity-80 pointer-events-none'
                  : 'opacity-40'
              }`}
            >
              Security
            </a>
          </Link>
          <button
            onClick={() => {
              window.Cookiebot?.renew()
            }}
            className={`font-demibold min-clickable-area w-fit-content leading-tight transition-opacity hover:opacity-80 focus:opacity-80 ${
              router.asPath === '/security' // Has to be the same as the href slug
                ? 'opacity-80 pointer-events-none'
                : 'opacity-40'
            }`}
          >
            Cookie Preferences
          </button>
        </div>
      </div>
      <LogoInfo
        className='xl:hidden' // Hidden on larger devices
      />
    </footer>
  )
}

const LogoInfo = ({ className }) => {
  const router = useRouter()

  return (
    <div
      className={`flex flex-col space-y-4 flex-shrink-0 pb-16 xl:pb-0 ${className}`}
    >
      <Link
        href='/'
        tabIndex={router.asPath === '/' ? -1 : 0}
        className={cn('min-clickable-area w-fit-content', {
          'pointer-events-none': router.asPath === '/',
        })}
        aria-label='Stellate'
      >
        <LogoGray
          aria-hidden='true'
          className='h-12 lg:h-16'
          width={138}
          height={38}
        />
      </Link>
      <div className='small-body-text text-neutral-40'>
        <p>© {new Date().getUTCFullYear()} Stellate, Inc.</p>
        <p>Formerly known as GraphCDN</p>
        <p>All rights reserved.</p>
      </div>

      <SocialChannels size='sm' />
    </div>
  )
}

const NavGroup = ({ className, groupName, children, ...rest }): JSX.Element => (
  <div
    className={`flex flex-col space-y-6 max-w-lg ${className || ''}`}
    aria-label={`${groupName} links`}
    {...rest}
  >
    <h4 className='label text-neutral-40 xl:mb-2'>{groupName}</h4>
    {children}
  </div>
)

const NavItem = ({ href, children, ...rest }): JSX.Element => {
  const router = useRouter()
  const isActive = href === router.asPath

  return (
    <Link
      href={href}
      className={`min-clickable-area flex items-center space-x-3 w-fit-content transition-opacity hover:opacity-80 focus:opacity-80 ${
        isActive ? 'opacity-80 pointer-events-none' : 'opacity-60'
      }`}
      {...rest}
      tabIndex={isActive ? -1 : 0}
    >
      {children}
    </Link>
  )
}

type ExternalResourceProps = {
  logo: React.ReactNode
  href: string
  name: string
  briefDescription: string
}

const ExternalResource = ({
  logo,
  href,
  name,
  briefDescription,
}: ExternalResourceProps): JSX.Element => (
  <a
    href={href}
    target='_blank'
    rel='noreferrer'
    className='min-clickable-area w-fit-content flex items-center space-x-3 opacity-60 transition-opacity hover:opacity-80 focus:opacity-80'
  >
    {logo}
    <div className='flex flex-col'>
      <span>{name}</span>
      <span className='small-body-text font-medium text-neutral-60'>
        {briefDescription}
      </span>
    </div>
  </a>
)
